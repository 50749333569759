import React from "react";
import { Link } from "gatsby";
import "../styles/blocks/pagination.scss";

const Pagination = (props) => {
  const {
    totalPages,
    canonicalPath,
    currentPage,
    index,
    options
  } = props;
  let pages = [];
  for (let i=1; i<=totalPages; i++) {
    pages.push({
      url: canonicalPath+((i > 1) ? i+"/" : ""),
      title: "Consulter la page "+i,
      children: i,
      currentPage: i===currentPage
    });
  }
  const PrevPage = () => {
    const previous = currentPage - 1;
    return (previous > 0)
      ? (<Link to={ canonicalPath+((previous > 1) ? previous+"/" : "") } className="previous" title="Consulter la page précédente">Page précédente</Link>)
      : (<></>);
  };
  const NextPage = () => {
    const next = currentPage + 1;
    return (next <= totalPages)
      ? (<Link to={ canonicalPath+((next > 1) ? next+"/" : "") } className="next" title="Consulter la page suivante">Page suivante</Link>)
      : (<></>);
  };
  const style = {};
  if (options.layout) {
    style.flexWrap = options.layout.flexWrap;
    if (options.layout.orientation === "vertical") {
      style.flexDirection = "column";
      style.alignItems = options.layout.justifyContent === "right"
        ? "end"
        : options.layout.justifyContent;
    } else {
      style.justifyContent = options.layout.justifyContent;
      style.flexDirection = "row";
    }
  }
  style.order = index;
  return (
    (totalPages > 1) ?
      (<div id="pagination" style={ style }>
        <PrevPage />
        <ul>
          { pages.map(e => (<li key={ e.children }>
            { 
              e.currentPage
                ? (<span>{e.children}</span>)
                : (<Link to={ e.url.slice(-1) === "/" ? e.url : e.url+"/"  } title={ e.title }>{ e.children }</Link>) 
            }
          </li>)) }
        </ul>
        <NextPage />
      </div>)
      : (<></>)
  );
};
export default Pagination;
